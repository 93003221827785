@import '~@sia/style-guide/src/colors.scss';

.privacy-policy {

  .content-separator-icon {
    margin-bottom: 48px;
  }

  p.effective-from {
    margin-bottom: 48px;

    .separator {
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .sequence {
    margin-right: 16px;
  }

  .bx--accordion__content {
    white-space: pre-line;
  }
}