@import '~@sia/style-guide/src/variables.scss';
.manage-directory {
    .upper-row {
        width: 100%;
        margin-bottom: 48px;
    }

    .lower-row {
        width: 100%;
    }
    .link{
        text-underline-offset: 3px;
        line-height: 18px;
    }
    .contact-block{
        padding-bottom: 48px;
    }
    .corporate-user,.single-user{
        margin-bottom: 0 !important;
    }
    .input-controls .info-content {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;
    }
    .info-block{
        margin-bottom: 0;
    }
    .bx--radio-button__appearance {
        margin-bottom: 0;
    }

    .checkbox {
        margin-bottom: 0;
    }

    .text-area {
        margin-top: 24px;
    }
    .membership{
        margin-bottom: 24px;
    }
    
    .details-block-wrapper{
        .info-block{
            min-height: 245px;
        }
        .tags-label{
            padding-bottom: 8px;
        }
        .tags{
            margin-bottom: 16px;
        }
        .tags:last-child{
            margin-bottom: 0;
        }
    }

    textarea {
        min-height: 245px;
    }
    @media (max-width: $carbon--grid-breakpoint-small-width-end) {
        .input-controls {
            margin-bottom: 24px;
        }
       .card-block{
        margin-top: 24px;
       }
       .text-area-wrapper{
        margin-bottom: 48px;
       }
       .upper-row > .fields-column:nth-child(2){
        margin-top: 24px;
       }
        .lower-row .membership, .details-block-wrapper{
            margin-bottom: 48px;
        }
    }
}