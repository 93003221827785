@import '@sia/style-guide/src/variables.scss';

.manage-project {
  .bx--grid {

    .bx--col-lg-8:nth-child(1) {
      padding-right: 8px;
    }

    .bx--col-lg-8:nth-child(2) {
      padding-left: 8px;
    }
  }

  .info-block {
    .file-uploader {
      margin: 0;
    }
  }

  @media (max-width: $carbon--grid-breakpoint-small-width-end) {
    .bx--grid {
      .bx--col-lg-8:nth-child(1) {
        padding-right: 0;
      }

      .bx--col-lg-8:nth-child(2) {
        padding-left: 0;
      }
    }
  }
}