@import '~@sia/style-guide/src/variables.scss';
@import '~@sia/style-guide/src/colors.scss';

.memberships {
  h2 {
    text-align: center;
  }

  .table {
    &.ahv_entries {
      td.invoice {
        padding-top: 11px;
        padding-bottom: 10px;

        svg {
          filter: $redFilter;
        }
      }
    }
  }

  .memberships-table-title {
    display: flex;
    justify-content: space-between;
  }

  .memberships-table-add {
    cursor: pointer;
  }

  .memberships-ahv-form-wrapper {
    display: flex;
    justify-content: center;

    .memberships-ahv-form {
      max-width: 245px;

      .number-input-with-formatting {
        width: 100%;
      }
    }

    #memberships_ahv_form_sum::placeholder {
      text-align: right;
    }

  }

  @media (max-width: $carbon--grid-breakpoint-small-width-end) {
    td {
      p {
        &:last-child {
          margin-bottom: 0;
        }

        label {
          color: $SIAred;
        }
      }
    }
  }
}