@import '~@sia/style-guide/src/variables.scss';

// @keyframes errorMessage {
//   0% {
//     opacity: 0.8;
//     transform: scale(0.8, 1);
//   }

//   100% {
//     opacity: 1;
//     transform: scale(1, 1);
//   }
// }

.address-error-message {
  display: block;
  color: $SIAred;
  margin-bottom: 24px;
  background-color: $SIAwhite;
  // animation-name: errorMessage;
  // animation-duration: 0.5s;
}

.add-address {
  display: flex;

  svg {
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}