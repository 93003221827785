@import "@sia/style-guide/src/variables.scss";

$filterBetweenSpacing: 16px;
.reference-gallery {
  .filters-line {
    display: flex;
  }

  .text-input.name {
    width: 66%;
    margin-right: $filterBetweenSpacing;
    padding-top: 24px;
  }

  .one-third {
    width: 32%;
    margin-right: $filterBetweenSpacing;

    &:last-child {
      margin-right: 0;
    }
  }

  .dropdown.sort {
    display: flex;
    justify-content: center;

    .bx--dropdown__wrapper--inline {
      grid-gap: 0 2px;

      .bx--label {
        color: $SIAgrayMiddle;
        padding-top: 1px;
      }

      .bx--dropdown {
        width: 170px;
      }
      .bx--list-box__menu {
        max-height: initial;
      }
    }
  }

  .pagination {
    margin-top: 24px;
  }

  @media (max-width: $carbon--grid-breakpoint-small-width-end) {
    .filters-line {
      display: initial;
    }

    .text-input.name {
      width: initial;
      margin-right: 0;
      padding-top: 0;
    }

    .one-third {
      width: initial;
      margin-right: 0;
    }
  }
}
