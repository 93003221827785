.reference-gallery-manage {
  .toolbar {
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;

    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
}