@import '@sia/style-guide/src/variables.scss';

$filterBetweenSpacing: 16px;

.Directory {
  .bx--grid {
    padding: 0;
  }
  .accordion .bx--accordion__content{
    padding: 32px 16px 14px 16px;
  }
  .DirectoryPreviews .DirectoryPreviews-container .bottom-section {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .DirectoryPreviews-Title {
    padding: 0;
  }

  .bx--col-lg-4:not(:first-child) {
    padding-left: 0;
  }

  .Directory-Name {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .Directory-Gutacher .bx--label {
    color: #4c4c4c;
  }

  .Directory-Gutachter-Checkbox .checkbox {
    margin-bottom: 2px;
  }

  .Directory-Search {
    margin: 0;
  }

  .Directory-CheckBoxes-Expert {
    color: #4c4c4c;
    margin-bottom: 0.5em;
    margin-top: -22px;
  }

  .check-box-wrapper {
    margin-top: 24px;
    display: flex;
    flex-flow: column;

    .checkbox {
      margin-bottom: 10px;
    }
  }

  .dropdown.sort {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }

  .pagination {
    margin-top: 24px;
  }

  @media (max-width: $carbon--grid-breakpoint-medium-width-end) {
    .bx--col-lg-4:not(:first-child) {
      padding-left: 16px;
    }
    .DirectoryPreviews .DirectoryPreviews-container .bottom-section {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .Directory-Name {
      padding-bottom: 24px;
    }

    .check-box-wrapper {
      top: 0px;
    }

    .Directory-Even {
      padding-left: 0;
    }
  }
  @media (max-width: $carbon--grid-breakpoint-small-width-end) {
    
    .DirectoryPreviews .DirectoryPreviews-container .bottom-section {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    
  }
}