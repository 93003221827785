@import '@sia/style-guide/src/variables.scss';

.DetailsPage {
    .DetailsPage-Title {
        p {
            margin-bottom: 0;
        }

        h1 {
            font-size: 28px;
            margin-bottom: 16px;
        }
    }

    .DetailsPage-Description {
        white-space: pre-wrap;
        margin-top: 48px;
        margin-bottom: 48px;
        padding-right: 48px;
        hyphens: auto;
        word-wrap: break-word;
    }


    .DetailsPage-Contact {
        .info-block:last-child {
            margin-bottom: 0;
        }
    }

    .DetailsPage-cta {
        margin-top: 24px;
        font-weight: bold;
    }

    .DetailsPage-Logo-container {
        margin-bottom: 16px;
        overflow: hidden;
        height: 135px;

        img {
            max-width: 100%;
        }
    }

    .DetailsPage-Address,
    .DetailsPage-Info {
        display: flex;
        flex-flow: column;

        & span {
            line-height: 1.3em;
        }
    }

    .productive-heading-03 {
        display: block;
        padding-top: 32px;
        padding-bottom: 16px;
        color: $SIAblack;
    }

    .DetailsPage-Email,
    .DetailsPage-Phone {
        display: block;
        margin-bottom: 16px;
    }

    @media (max-width: $carbon--grid-breakpoint-small-width-end) {
        .DetailsPage-Logo-Container__empty {
            display: none;
        }
    }


}

.DetailsPage-Other {
    margin-top: 48px;

    p {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 16px !important;
    }

    .job-previews {
        padding: 0;

        .bx--grid {
            padding: 0;

            .bx--row {
                margin: 0;
            }
        }
    }
}