@import '~@sia/style-guide/src/variables.scss';

.invoices {
  .table {

    td {
      p {
        &:last-child {
          margin-bottom: 0;
        }

        label {
          color: $SIAred;
        }
      }
    }
  }
}