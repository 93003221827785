@import '@sia/style-guide/src/variables.scss';

.MemberDetailsHeader {
  justify-content: flex-start;

  .MemberDetailsHeader-Image {
    min-height: 155px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .MemberDetailsHeader-Name {
    margin-bottom: 16px;
  }



  .MemberDetailsHeader-Id {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
  }

  .MemberDetailsHeader-Noimage {
    // padding: 0;
  }

  @media (max-width: $carbon--grid-breakpoint-small-width-end) {
    .MemberDetailsHeader-Id {
      padding-left: 0;
    }

    .MemberDetailsHeader-Id p {
      margin-top: 48px;
    }
  }
}