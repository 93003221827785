@import '@sia/style-guide/src/variables.scss';

.CompanyPage {
    padding: 0;

    #Directory-Right-col {
        position: relative;
        left: -15px;
    }

    .Logo-Wrapper {
        height: 244px;
        text-align: center;

        .Corporate-Logo {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .MemberDetailsHeader-Backbutton {
        text-align: center;
    }

    .CompanyPage-Row {
        justify-content: flex-end;
        margin-top: 48px;
    }

    .CompanyPage-Col {
        padding: 0;
        padding-right: 16px;
    }

    .CompanyPage-Reference-Row {
        margin-top: 48px;

        .job-previews {
            padding: 0;
        }

        &:nth-child(3) {
            margin-top: 24px;
        }

        & p {
            margin-bottom: 16px;
            font-weight: bold;
        }

        .reference-gallery-item-previews .row {
            margin: 0;
        }
    }

    .logo {
        padding-right: 16px !important;
    }

    @media (max-width: $carbon--grid-breakpoint-medium-width-end) {
        #Directory-Right-col {
            left: 0px;
        }


        .Logo-Wrapper {
            padding-bottom: 48px;
        }

        .CompanyPage-Reference-Row {
            &:nth-child(3) {
                margin-top: 48px;
            }
        }

        padding: 0 16px;

        .CompanyPage-Row {
            justify-content: space-between;
        }

        .CompanyPage-Col {
            left: 0;
        }

    }

    @media (max-width: $carbon--grid-breakpoint-small-width-end) {
        #Specialis_RightCol {
            padding-top: 0;
        }

    }
}