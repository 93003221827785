@import '@sia/style-guide/src/variables.scss';

.FragmentTitle {
    color: $SIAred;

    & span {
        display: block;
        padding-bottom: 5px;
    }
}

.Address-Title,
.Address-Phone,
.Address-Email,
.Address-Website {
    margin-bottom: 16px !important;
}

.Address-SocialLinks svg {
    margin: 12px;
}

.Indivisual,
.Branch {
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0 !important;
    }
}

.ExtracInfo-List {
    list-style-type: square;
    list-style-position: inside;
}