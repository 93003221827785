@import '~@sia/style-guide/src/variables.scss';

.Services-Container-left {
    margin-right: 8px;
}

.Services-Container-right {
    margin-left: 8px;
}

.Services-Container {
    display: flex;
    flex-flow: column;
    max-width: 100%;
    gap: 16px;
}

@media (max-width: $carbon--grid-breakpoint-medium-width-end) {
    .Services-Container-left {
        margin-left: 16px;
    }

    .Services-Container-right {
        margin-right: 16px;
    }
}

@media (max-width: $carbon--grid-breakpoint-small-width-end) {
    .Services-Container-left {
        margin-right: 16px;
    }

    .Services-Container-right {
        margin-top: 16px;
        margin-left: 16px;
    }
}