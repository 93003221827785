@import '~@sia/style-guide/src/colors.scss';

.managable-image {
  height: 136px;
  display: flex;
  border-bottom: 1px solid $lightGray;

  &.dragging {
    visibility: hidden;
  }

  .left-icon-actions {
    display: flex;
    align-items: center;

    .draggable {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: move;
      }
    }

    .placeholder {
      margin-right: 16px;
      position: relative;

      svg {
        width: 96px;
        height: 96px;
        fill: $gray;
      }

      img {
        max-width: 96px;
        max-height: 96px;
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: $darkGray;
      }
    }
  }

  div.right-area {
    padding-top: 24px;
    flex-grow: 1;

    .text-input {
      margin: 0;
    }

    svg.remove {
      &:hover {
        cursor: pointer;
      }

      margin-top: 10px;
      float: right;
    }
  }
}