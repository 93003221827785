@import "@sia/style-guide/src/variables";
@import "@sia/style-guide/src/spacings";

.project-page {
  p.subtitle {
    font-weight: bold;
    font-size: 14px;

    &.corporate {
      margin-bottom: 8px;
      cursor: pointer;
      display: inline-block;
    }

    &.more-projects {
      margin-bottom: $spacing-05;
    }
  }

  h2 {
    margin-bottom: $spacing-05;
  }

  .tags-group {
    margin-bottom: 43px; // should be 48 from tags, but tags has own margin
  }

  p.description {
    margin-bottom: 48px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0;
    padding-right: 24px;
    margin-right: 16px;
    hyphens: auto;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .info-block {
    margin-bottom: 24px;
  }

  @media (max-width: $carbon--grid-breakpoint-small-width-end) {
    p.description {
      margin-bottom: 24px;
    }

    .links {
      margin-bottom: 48px;

      p:last-child {
        margin: 0;
      }
    }
  }
}