@import "~@sia/style-guide/src/variables.scss";
$carbon--grid-breakpoint-large-width-end: calc(
  $carbon--grid-breakpoint-large-width - 1px
);

.jobs-filters {
  padding-top: 24px;

  .filters {
    padding: 0 16px;
  }

  .bx--grid.reset-spacings {
    .bx--row {
      .region,
      .contract-type {
        padding-left: 16px;
      }
    }
  }
  .bx--list-box--expanded .bx--list-box__menu {
    max-height: fit-content;
  }
  .dropdown.sort {
    display: flex;
    justify-content: center;

    .bx--dropdown__wrapper--inline {
      grid-gap: 0 2px;

      .bx--label {
        color: $SIAgrayMiddle;
        padding-top: 1px;
      }

      .bx--dropdown {
        width: 170px;
      }
    }
  }

  .bx--grid.reset-spacings {
    .bx--row {
      @media (max-width: $carbon--grid-breakpoint-medium-width-end) {
        .region {
          padding-right: 8px;
          padding-left: 0px;
        }

        .contract-type {
          padding-left: 8px;
        }
      }

      @media (max-width: $carbon--grid-breakpoint-small-width-end) {
        .region {
          padding-right: 0;
        }

        .contract-type {
          padding-left: 0;
        }
      }
    }
  }

  .dropdown.sort {
    display: flex;
    justify-content: center;
  }
}
