.Description-Column{
    display: flex;
    flex-flow: column;
    .text-area{
        flex: 1;
        margin-bottom: 1rem;
        .bx--form-item{
            height: 100%;
            .bx--text-area__wrapper{
                height: 100%;
            }
        }
    }
}