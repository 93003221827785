@import '~@sia/style-guide/src/colors.scss';

.base-data {
  .file-uploader {
    margin-bottom: 16px;
  }

  .chosen-image {
    display: flex;
    margin-bottom: 16px;

    .preview {
      width: 140px;
      height: 50px;
      background: $veryLightGray;
      display: flex;
      justify-content: center;

      img {
        margin: 2px 0;
        max-width: 100%;
        object-fit: contain;
      }
    }

    .filename {
      margin: 0 16px;
      width: 159px;
      line-height: 17px;
      overflow-wrap: break-word;
    }

    svg {
      margin-left: auto;
      flex-shrink: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }
}